import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import { useUser } from "context/userContext";

const query = graphql`
  query {
    file(relativePath: { eq: "logo.png" }) {
      logo: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

const Header = ({ isActive, setIsActive }) => {
  const {
    userDetail: { isLoggedIn, profile },
  } = useUser();
  console.log("in header", isActive);
  const {
    file: { logo },
  } = useStaticQuery(query);

  const toggleSidebar = event => {
    event.preventDefault();
    event.stopPropagation();
    console.log("in toggle", isActive, event);
    setIsActive(!isActive);
  };
  return (
    <header id="header">
      <a href="#sidebar" className="toggle" onClick={toggleSidebar}>
        toggle
      </a>
      <Link to="/" className="logo">
        <span className="image image-logo">
          <Img fluid={logo.fluid} />
        </span>
      </Link>
      <Link to="/" className="title">
        <strong>علوم فيزيائية</strong>
      </Link>
      {isLoggedIn ? (
        <div className="loginInfo">
          <span style={{ color: "gold" }}>
            &#9733;{profile?.goldStars || 0}
          </span>
          <span style={{ color: "silver" }}>
            &#9733;{profile?.silverStars || 0}
          </span>
        </div>
      ) : (
        <Link to="/Signin" className="button primary">
          سجل مجانا
        </Link>
      )}
    </header>
  );
};

export default Header;

import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import SEO from "../components/seo";

import Header from "./header";
import Banner from "./Banner";
import BodyClass from "./BodyClass";
import PrintingHeader from "./PrintingHeader";
import { InitModulesContext } from "../hooks/useContext";
import OfferCards from "./offers/offerCards";
import Menu from "./Menu";

const Layout = ({
  children,
  showBanner = false,
  title,
  description = "",
  featuredCourse = {},
  classTitle = "major",
  classSection = "",
  image = {},
  location,
}) => {
  const { breackpoints } = useContext(InitModulesContext);
  const [isActive, setIsActive] = useState(breackpoints.active(">large"));
  return (
    <>
      <SEO title={title} image={image} description={description} lang="ar" />
      <BodyClass />
      <div id="wrapper">
        <main id="main" className={isActive ? "active" : ""}>
          <div className="inner">
            <Header isActive={isActive} setIsActive={setIsActive} />
            {showBanner ? <Banner featuredCourse={featuredCourse} /> : null}
            {classSection === "lesson" ? <PrintingHeader page={false} /> : null}
            {classSection === "Main" ? (
              <section style={{ paddingBottom: 0 }}>
                <OfferCards to={`/App/dashboard#subscriptions#baridi`} />
              </section>
            ) : null}

            <section className={classSection}>
              <header className={classTitle}>
                <h2>{title}</h2>
              </header>
              {children}
            </section>
          </div>
        </main>
        <Menu
          location={location}
          setIsActive={setIsActive}
          isActive={isActive}
        />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
